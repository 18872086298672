<template>
    <div class="projectForPhone">
        <div class="top-tab-dv">
            <div
                class="top-tab-dv-item"
                :class="active == 0 ? 'active' : ''"
                @click="checkPage(0)"
            >
                {{ $t('lang.Solution') }}
            </div>
            <div
                class="top-tab-dv-item"
                :class="active == 1 ? 'active' : ''"
                @click="checkPage(1)"
            >
                {{ $t('lang.IoTPlatform') }}
            </div>
            <div
                class="top-tab-dv-item"
                :class="active == 2 ? 'active' : ''"
                @click="checkPage(2)"
            >
                {{ $t('lang.IntelligentHardware') }}
            </div>
        </div>
        <div style="height: 50px"></div>
        <!-- 解决方案 -->
        <div class="page1" v-if="active == 0 ? true : false">
            <div style="width: 100%">
                <img src="../assets/images/Project/u310_div.png" width="100%"/>
            </div>
            <div
                class="project-view1-content-item"
                v-for="(item, index) in pageOneData"
                :key="index"
            >
                <el-row>
                    <el-col :span="22">
                        <div class="project-view1-content-item-title">
                            <div class="nowarp">
                                <span class="text1">{{ item.title }}</span>
                                <span class="text2">{{ item.title2 }}</span>
                            </div>
                            <div class="nowarp" style="margin-top: 20px">
                                <span class="text3">{{ $i18n.locale == 'zh' ? item.info : item.info2 }}</span>
                            </div>
                        </div>
                    </el-col>
                    <el-col :span="2">
                        <div class="project-view1-content-item-img">
                            <img
                                class="right-point-img"
                                src="../assets/images/Project/01_u416.svg"
                            />
                        </div>
                    </el-col>
                </el-row>
            </div>
        </div>
        <!-- IOT平台 -->
        <div class="page2" v-if="active == 1 ? true : false">
            <div style="width: 100%">
                <img src="../assets/images/IOT/u1142_div.png" width="100%"/>
            </div>
            <div class="IOT-view1-content">
                <div class="IOT-view1-content-text1">{{ $t('lang.IoTInfo.title') }}</div>
                <div style="width: 80px; border-bottom: 2px solid #95f204"></div>
                <div class="o2s-img">
                    <img
                        :src="`${host}/resource/images/kcow/1.jpg`"
                        alt=""
                        width="100%"
                    />
                </div>
                <div class="IOT-view1-content-text2">
                    <ul v-if="$i18n.locale == 'zh'">
                        <li style="">
                            <span>青岛科创信达科技有限公司致力于打造Only OneSystem大平台，将传统小科爱牧物联网平台与专为养殖深度定制开发的ERP系统、OA办公系统、ARS应收账款系统、EQ电子报价系统的进行全面打通、深度融合，解决集团化企业的信息孤岛问题，为集团管理降本，为集团养殖增效。</span>
                        </li>
                        <li style="">
                            <span>通过物联网、大数据、云计算等技术的深度融合，打造创新、协调、绿色、开放、共享的互联网平台，解决用户痛点，实现低风险、快产出、低人工、高品质、高收益的数智化养殖，为客户引流，为品牌赋能。</span>
                        </li>
                    </ul>
                    <ul v-else>
                        <li style="">
                            <span>Kechuang Xinda is committed to building the Only One System platform, integrating the traditional Xiaoke AI Farm IoT platform with the ERP system, OA office system, ARS accounts receivable system, and EQ electronic quotation system .Solve the information island problem of group enterprises, reduce the cost of group management, and increase the efficiency of group breeding.</span>
                        </li>
                        <li style="">
                            <span>Through the deep integration of the Internet of Things, big data, cloud computing and other technologies, to create an innovative, coordinated, green, open and sharing Internet platform, to solve the pain points of users, to achieve low risk, fast output, low labor, high quality and high income digitalized breeding, to attract customers and empower brands.</span>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="IOT-view1-content mt5">
                <div class="IOT-view1-content-text1">{{ $t('lang.TheGrowthOfXiaokeAimu') }}</div>
                <div style="width: 80px; border-bottom: 2px solid #95f204"></div>
                <div class="years-dv">
                    <img src="../assets/images/phone/years.png" alt="" width="100%"/>
                </div>
            </div>
            <div class="IOT-view1-content mt5">
                <div class="IOT-view1-content-text1">{{ $t('lang.OurAdvantages') }}</div>
                <div style="width: 80px; border-bottom: 2px solid #95f204"></div>
                <div class="advantage-dv mt20">
                    <div
                        class="advantage-dv-item"
                        v-for="item in pagetwoData"
                        :key="item.name"
                    >
                        <img :src="item.src" style="width: 8vw; height: 8vw"/><br/>
                        {{ $i18n.locale == 'zh' ? item.name : item.name2 }}
                    </div>
                </div>
            </div>
        </div>
        <!-- 智能硬件 -->
        <div class="page3" v-if="active == 2 ? true : false">
            <div style="width: 100%" ref="fixdv">
                <img src="../assets/images/Hardware/u1302_div.png" width="100%"/>
            </div>
            <div class="top-scroll-dv " :class="topSize?'top-scroll-dv2':''">
                <div
                    class="top-scroll-dv-item"
                    :class="bctive == index ? 'bctive' : ''"
                    v-for="(item, index) in ($i18n.locale == 'zh' ? haedWareType : haedWareType2)"
                    :key="item"
                    @click="checkDevice(index)"
                >
                    {{ item }}
                </div>
            </div>

            <div class="device-img-dv">
                <div class="device-img-item-dv" v-for="item in imgData" :key="item.title">
                    <div class="device-img"><img :src="item.imgUrl"></div>
                    <div class="device-title">{{ item.title }}</div>
                </div>
            </div>
        </div>
        <AddressForPhone></AddressForPhone>
    </div>
</template>
<script>
import AddressForPhone from "./addressForPhone";

export default {
    name: "projectForPhone",
    components: {
        AddressForPhone,
    },
    data() {
        return {
            active: 0,
            topSize: false,
            pageOneData: [
                {
                    title: "智慧养殖数智化解决方案",
                    title2: "/DIOT Solution Of Intelligent Breeding",
                    info: "简述：将物联网智能化感知、信息传输和智能控制技术与养殖业结合起来，利用先进的智能硬件… …",
                    info2: "Brief description: combine the intelligent perception, information transmission and intelligent control technology of the Internet of things with the aquaculture industry, and make use of advanced intelligent hardware..."
                },
                {
                    title: "数智化洗消烘干解决方案",
                    title2: "/DIOT Cleaning & Stoving Solution",
                    info: "简述：将物联网智能化感知、信息传输和智能控制技术与养殖业结合起来，利用先进的智能硬件… …",
                    info2: "Brief description: combine the intelligent perception, information transmission and intelligent control technology of the Internet of things with the aquaculture industry, and make use of advanced intelligent hardware..."
                },
                {
                    title: "IB2S智能楼房解决方案",
                    title2: "/Intelligent Building Breeding Solution",
                    info: "简述：将物联网智能化感知、信息传输和智能控制技术与养殖业结合起来，利用先进的智能硬件… …",
                    info2: "Brief description: combine the intelligent perception, information transmission and intelligent control technology of the Internet of things with the aquaculture industry, and make use of advanced intelligent hardware..."
                },
                {
                    title: "集中报警解决方案",
                    title2: "/Center Alarm Warn Solution",
                    info: "简述：将物联网智能化感知、信息传输和智能控制技术与养殖业结合起来，利用先进的智能硬件… …",
                    info2: "Brief description: combine the intelligent perception, information transmission and intelligent control technology of the Internet of things with the aquaculture industry, and make use of advanced intelligent hardware..."
                },
            ],
            pagetwoData: [
                {name: "一览众山小", name2: 'Global', src: require("../assets/images/phone/u251.png")},
                {name: "极致的体验", name2: 'Experience', src: require("../assets/images/phone/u254.png")},
                {name: "十分的安全", name2: 'Safety', src: require("../assets/images/phone/u257.png")},
                {name: "精细的采集", name2: 'Collection', src: require("../assets/images/phone/u260.png")},
                {name: "深度的挖掘", name2: 'Analysis', src: require("../assets/images/phone/u263.png")},
                {
                    name: "一触即达农场",
                    name2: 'Usability',
                    src: require("../assets/images/phone/u266.png"),
                },
            ],
            haedWareType: [
                "养猪专用环控器",
                "家禽专用环控器",
                "守护卫士",
                "物联网",
                "辅助精灵",
                "传感器",
            ],
            haedWareType2: [
                "Pigs",
                "Poultry",
                "Guard",
                "IOT",
                "auxiliary",
                "Sensors",
            ],
            bctive: 0,
            imgData: [],
            data1: [
                {
                    imgUrl: require("../assets/images/Hardware/K9.jpg"),
                    title: "智能环境控制器 | K9",
                },
                {
                    imgUrl: require("../assets/images/Hardware/K16.jpg"),
                    title: "智能环境控制器 | K16",
                },
                {
                    imgUrl: require("../assets/images/Hardware/K16Pro.jpg"),
                    title: "智能环境控制器 | K16 Pro",
                },
                {
                    imgUrl: require("../assets/images/Hardware/BC-M1.jpg"),
                    title: "楼房大脑 | BC-M1",
                },
                {
                    imgUrl: require("../assets/images/Hardware/BC-S1.jpg"),
                    title: "楼房大脑 | BC-S1",
                },
                {
                    imgUrl: require("../assets/images/Hardware/H2.jpg"),
                    title: "烘干房控制器丨H2",
                },
                {
                    imgUrl: require("../assets/images/Hardware/D1000.jpg"),
                    title: "数字风机控制器丨D1000",
                },
                {
                    imgUrl: require("../assets/images/Hardware/DC1.jpg"),
                    title: "刮粪控制器丨DC1",
                },
            ],
            data2: [
                {
                    imgUrl: require("../assets/images/Hardware/Future80.jpg"),
                    title: "家禽大脑 | Future 80",
                },
                {
                    imgUrl: require("../assets/images/Hardware/Future100.jpg"),
                    title: "家禽大脑 | Future 100",
                },
                {
                    imgUrl: require("../assets/images/Hardware/FutureX.jpg"),
                    title: "家禽超脑 | Future X",
                },
                {
                    imgUrl: require("../assets/images/Hardware/H2.jpg"),
                    title: "烘干房控制器丨H2",
                },
                {
                    imgUrl: require("../assets/images/Hardware/D1000.jpg"),
                    title: "数字风机控制器丨D1000",
                },
            ],
            data3: [
                {
                    imgUrl: require("../assets/images/Hardware/S1.jpg"),
                    title: "安全卫士 | S1",
                },
                {
                    imgUrl: require("../assets/images/Hardware/A1.jpg"),
                    title: "守护报警器 | A1",
                },
                {
                    imgUrl: require("../assets/images/Hardware/A2.jpg"),
                    title: "数据中心报警器 | A2",
                },
                {
                    imgUrl: require("../assets/images/Hardware/A3.jpg"),
                    title: "网络报警中心 | A3",
                },
            ],
            data4: [
                {
                    imgUrl: require("../assets/images/Hardware/4G.jpg"),
                    title: "物联网 | 无线网关",
                },
                {
                    imgUrl: require("../assets/images/Hardware/O2S.jpg"),
                    title: "物联网 | 小科爱牧O2S大数据平台",
                },
                {
                    imgUrl: require("../assets/images/Hardware/Line.jpg"),
                    title: "物联网 | 有线网关",
                },
            ],
            data5: [
                {
                    imgUrl: require("../assets/images/Hardware/Voltage.jpg"),
                    title: "辅助精灵 | 调压模块",
                },
            ],
            data6: [
                {
                    imgUrl: require("../assets/images/Hardware/NP.jpg"),
                    title: "传感器 | 静压",
                },
                {
                    imgUrl: require("../assets/images/Hardware/Humi.jpg"),
                    title: "传感器 | 湿度",
                },
                {
                    imgUrl: require("../assets/images/Hardware/Humi&CO2.jpg"),
                    title: "传感器 | 湿度&CO2",
                },
                {
                    imgUrl: require("../assets/images/Hardware/CO2.jpg"),
                    title: "传感器 | CO2",
                },
                {
                    imgUrl: require("../assets/images/Hardware/NH3.jpg"),
                    title: "传感器 | NH3",
                },
                {
                    imgUrl: require("../assets/images/Hardware/Temp.jpg"),
                    title: "传感器 | 温度",
                },
            ],
            host:
                process.env.VUE_APP_BASE_API.split(":")[0] +
                ":" +
                process.env.VUE_APP_BASE_API.split(":")[1],
        };
    },
    mounted() {
        this.imgData = this.data1
        window.addEventListener('scroll', this.handleScroll, true)
    },
    methods: {
        handleScroll() {

            if (this.$refs.fixdv.getBoundingClientRect().top <= -47) {
                this.topSize = true
            } else {
                this.topSize = false
            }
        },
        checkPage(index) {
            this.active = index;
        },
        checkDevice(index) {
            this.bctive = index;
            switch (index) {
                case 0:
                    this.imgData = this.data1
                    break
                case 1:
                    this.imgData = this.data2
                    break
                case 2:
                    this.imgData = this.data3
                    break
                case 3:
                    this.imgData = this.data4
                    break
                case 4:
                    this.imgData = this.data5
                    break
                case 5:
                    this.imgData = this.data6
                    break

            }
        },
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.handleScroll, true)
    }
};
</script>
<style lang="scss" scoped>
.top-tab-dv {
    z-index: 999;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: #fff;
    text-align: center;
    height: 50px;
    line-height: 50px;
    /*  background: rgba($color: #000000, $alpha: 0.8); */
    background-color: #333333;
    box-sizing: border-box;
    border-top: solid 1px #444444;
    font-weight: 700;
    position: fixed;
    top: 50px;
    width: 100%;
}

.top-tab-dv-item {
    width: 33%;
    border: solid 1px #444444;
    font-size: 12px;
}

.active {
    color: #a0f31e;
    border-bottom: solid 2px #a0f31e;
}

.project-view1-content-item {
    background-color: #f7f7f7;
    margin-top: 20px;
    width: 90%;
    margin: 20px auto 0 auto;
    padding: 20px 10px;
}

.text1 {
    font-size: 0.8rem;
    font-weight: 700;
}

.nowarp {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.text2 {
    font-size: 0.6rem;
}

.text3 {
    font-size: 0.5rem;
}

.right-point-img {
    width: 10px;
    margin-top: 20px;
    margin-left: 15px;
}

.IOT-view1-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    background-color: #000000;
    color: #fff;

    .IOT-view1-content-text1 {
        font-family: "PingFangSC-Semibold", "PingFang SC Semibold", "PingFang SC",
        sans-serif;
        font-weight: 650;
        font-style: normal;
        font-size: 22px;
        text-align: center;
        padding-bottom: 20px;
        margin-top: 30px;
    }

    .IOT-view1-content-text2 {
        font-family: "PingFangTC-Regular", "PingFang TC", sans-serif;
        font-weight: 400;
        font-style: normal;
        font-size: 12px;
        line-height: 200%;
        color: #fff;
        padding-right: 20px;
    }
}

.o2s-img {
    width: 90%;
    margin: 20px auto 0 auto;
}

.years-dv {
    width: 60%;
    margin: 30px auto;
}

.advantage-dv {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
}

.advantage-dv-item {
    text-align: center;
    width: 50px;
    font-size: 0.6rem;

    img {
        width: 60%;
    }
}

.top-scroll-dv {
    width: 100%;
    /* background: rgba($color: #000000, $alpha: 0.8); */
    background-color: #333333;
    color: #fff;
    overflow-x: scroll;
    display: flex;
    flex-direction: row;
    padding: 16px 0;
    font-size: 0.8rem;
    box-sizing: border-box;
}

.top-scroll-dv2 {
    position: fixed;
    top: 103px;
}

.top-scroll-dv-item {
    flex-shrink: 0;
    padding: 0 16px;
}

.bctive {
    color: #a0f31e;
}

.device-img-dv {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
}

.device-img-item-dv {
    width: 45%;
    margin-left: 3.3%;
    font-size: 0.8rem;
    text-align: center;
    margin-top: 20px;

    img {
        width: 100%;
    }
}

.device-img {
    width: 100%;
    box-sizing: border-box;
    border: solid 1px #D8D8D8;
}

.device-title {
    height: 40px;
    line-height: 40px;
    background-color: #333333;
    color: #fff;
}

.page3 {
    min-height: 550px;
}
</style>
